<template>
  <div>
    <prozess-metastatus-table
      :custom-service="service"
      :is-system-settings-permissions="true"
      :resource-name="resourceName"
    />
  </div>
</template>

<script>
import ProzessMetastatusTable from '@/@core/components/ProzessMetastatusTable.vue'
import MetastatusTypes from '@/constants/metastatusTypes'
import { metastatusService } from '@/services/metastatus'

const resourceName = MetastatusTypes.SOURCE

export default {
  components: {
    ProzessMetastatusTable,
  },
  data() {
    return {
      MetastatusTypes,
      resourceName,
      service: metastatusService(resourceName),
    }
  },
}
</script>
